import store from '../../framework/store';

export function initBehaviors({ context = document } = {}) {
	const promises = [];
	const elements = Array.from(
		context.querySelectorAll('[data-behavior]:not([data-instance-init])')
	);
	// Add context element itself to array if behavior present
	if (
		context.hasAttribute &&
		context.hasAttribute('data-behavior') &&
		!context.hasAttribute('data-instance')
	) {
		elements.unshift(context);
	}

	elements.forEach(element => {
		element.setAttribute('data-instance-init', 'true');
		const behaviorAttribute = element.getAttribute('data-behavior');
		const behaviors = behaviorAttribute.split(' ').filter(name => name);
		behaviors.forEach(behavior => {
			const pathPaths = behavior.split('/');
			const isChildBehavior = pathPaths.length > 1;
			const behaviorName = isChildBehavior ? pathPaths.pop() : behavior;
			const behaviorPath = isChildBehavior ? BEHAVIORS[pathPaths.shift()] : BEHAVIORS[behaviorName];

			// NOTICE, webpack gets confused easely, keep this formatted template with both variables!
			const behaviorPromise = import(
				/* webpackInclude:  /(?<!\.spec\.)(?<!\.test\.)(js|ts)$/ */
				`../../components/${behaviorPath}/${behaviorName}`
			)
				.then(({ default: Component }) => {
					element.component = typeof Component === 'function' && new Component(element);
					element.component && store.behaviourInstances.push(element.component);
				})
				.catch(err => {
					console.error(`Failed to load ${behaviorPath}`, err.stack || err);
				});
			promises.push(behaviorPromise);
		});
	});

	return Promise.all(promises);
}

export function destroyBehaviors({ context = null, destroyInstances = true } = {}) {
	if (context) {
		const elements = Array.from(context.querySelectorAll('[data-behavior][data-instance]'));

		// Add context element itself to array if behavior present
		if (
			context.hasAttribute &&
			context.hasAttribute('data-behavior') &&
			context.hasAttribute('data-instance')
		) {
			elements.unshift(context);
		}

		for (let a = 0; a < elements.length; a++) {
			const element = elements[a];
			const instanceAttribute = element.getAttribute('data-instance');
			const instances = instanceAttribute.split(' ');

			for (let b = 0; b < instances.length; b++) {
				const instanceName = instances[b];
				const instanceId = instanceName.split('__')[1];

				if (instanceId && destroyInstances) {
					const index = store.behaviourInstances.findIndex(item => item.instanceId === instanceId);
					if (index >= 0) {
						store.behaviourInstances[index].cleanup && store.behaviourInstances[index].cleanup();
						store.behaviourInstances.splice(index, 1);
					}
				}
			}
			element.removeAttribute('data-instance');
		}
	} else {
		// eslint-disable-next-line no-console
		console.error(
			'To destroy/reset behaviors you must provide a context to the function in the form "destroyBehaviors(domElement)"'
		);
	}
}

export function resetBehaviorNames({ context = null } = {}) {
	destroyBehaviors({ context, destroyInstances: false });
}
